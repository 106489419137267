import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_NAME, NotificationState } from './notification.model';

export const selectFeature = createFeatureSelector<NotificationState>(FEATURE_NAME);

export const selectNotifications = createSelector(selectFeature, (state) => {
  return state.notifications;
});

export const selectNotificationsCounter = createSelector(selectFeature, (state) => {
  return state.notifications?.length;
});
