import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ptc-back-button',
  templateUrl: './ptc-back-button.component.html',
  styleUrl: './ptc-back-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PtcBackButtonComponent {
  @Input() routerLink: string;

  @Input() disabled: boolean;

  @Output() back = new EventEmitter<void>();

  click() {
    this.back.emit();
  }
}
