import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from '../router';
import { AuthState } from '../auth/data/auth.model';
import { authReducer } from '../auth/data/auth.reducer';

export interface AppState {
  auth: AuthState;
  router: RouterReducerState<RouterStateUrl>;
}

export const metaReducers: MetaReducer[] = [];

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  router: routerReducer
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');
