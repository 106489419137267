import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PtcButtonToggleGroupOption } from './ptc-button-toggle-group.model';

@Component({
  selector: 'app-ptc-button-toggle-group',
  templateUrl: './ptc-button-toggle-group.component.html',
  styleUrl: './ptc-button-toggle-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PtcButtonToggleGroupComponent<T> {
  @Input() options: PtcButtonToggleGroupOption<T>[] = [];

  @Input() selectedOption: PtcButtonToggleGroupOption<T>;
  @Output() selectedOptionChange: EventEmitter<PtcButtonToggleGroupOption<T>> = new EventEmitter<PtcButtonToggleGroupOption<T>>();

  onSelectOption(option: PtcButtonToggleGroupOption<T>) {
    this.selectedOptionChange.emit(option);
  }
}
