import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectNotifications, selectNotificationsCounter } from './notification.selectors';
import { NotificationActions } from './notification.actions';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  readonly notifications$ = this.store.select(selectNotifications);
  readonly notificationsCounter$ = this.store.select(selectNotificationsCounter);

  constructor(private store: Store) {}

  loadNotifications() {
    this.store.dispatch(NotificationActions.getAll());
  }
}
