import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NotificationService } from '../../store/notification.service';
import { NotificationType } from '../../store/notification.model';

@Component({
  selector: 'app-notification-menu-item',
  templateUrl: './notification-menu-item.component.html',
  styleUrl: './notification-menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationMenuItemComponent implements OnInit {
  notifications$ = this.notificationService.notifications$;
  notificationsCounter$ = this.notificationService.notificationsCounter$;

  NotificationType = NotificationType;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notificationService.loadNotifications();
  }
}
