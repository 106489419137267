import { EntityMetadataMap, PropsFilterFnFactory } from '@ngrx/data';

// Example
// https://github.com/johnpapa/angular-ngrx-data/blob/master/src/app/store/entity/entity-metadata.ts

const entityMetadata: EntityMetadataMap = {};

// because the plural of "hero" is not "heros"
const pluralNames = {
  /*City: 'Cities'*/
};

export const entityConfig = {
  entityMetadata,
  pluralNames
};

// FILTERS AND SORTERS

// Can't embed these functions directly in the entityMetadata literal because
// AOT requires us to encapsulate the logic in wrapper functions

/** Filter for entities whose name matches the case-insensitive pattern */
export function filterByName<T extends { name: string }>(entities: T[], pattern: string) {
  return PropsFilterFnFactory<T>(['name'])(entities, pattern);
}

/** Filter for entities whose id matches the pattern */
export function filterById<T extends { id: number }>(entities: T[], pattern: string) {
  const patternIds = new Set(pattern ? pattern.split(',').map((id) => parseInt(id, 10)) : []);
  return entities.filter((e) => patternIds.has(e.id));
}

/** Sort Comparer to sort the entity collection by its name property */
export function sortByName(a: { name: string }, b: { name: string }): number {
  return a.name.localeCompare(b.name);
}

/** Sort Comparer to sort the entity collection by its id property */
export function sortByIdDesc(a: { id: number }, b: { id: number }): number {
  return b.id - a.id;
}
