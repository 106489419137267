<ng-container>
  @if (tableConfiguration.filter) {
    @if (tableConfiguration.filter.search) {
      <mat-form-field appearance="outline" class="m-t-4">
        @if (tableConfiguration.filter.search.label) {
          <mat-label>{{ tableConfiguration.filter.search.label }}</mat-label>
        }
        <input matInput (keyup)="applyFilter($event)" [placeholder]="tableConfiguration.filter.search.placeholder ?? ''" #input />
      </mat-form-field>
    }
    @if (tableConfiguration.filter.buttonToggle) {
      <app-ptc-button-toggle-group
        style="float: right"
        [options]="tableConfiguration.filter.buttonToggle.options"
        [(selectedOption)]="selectedFilterOption"
        (selectedOptionChange)="applyFilter()"
      ></app-ptc-button-toggle-group>
    }
  }
  @if (matDataSource) {
    <table mat-table [dataSource]="matDataSource" class="w-100">
      @for (column of tableConfiguration.columns; track column) {
        <ng-container [matColumnDef]="column.columnId">
          <th mat-header-cell *matHeaderCellDef class="f-w-600 mat-subtitle-1 f-s-14">
            @if (column.type !== 'image') {
              {{ column.label }}
            }
          </th>
          <td mat-cell *matCellDef="let element">
            @if (column.type === 'user-image') {
              <ng-container #imageColumn>
                <img
                  referrerpolicy="no-referrer"
                  [ngSrc]="element[column.dataSourceProperty!] | defaultImg"
                  width="35"
                  height="35"
                  class="rounded-circle object-cover"
                />
              </ng-container>
            }
            @if (column.type === 'image') {
              <ng-container #imageColumn>
                <img
                  referrerpolicy="no-referrer"
                  [ngSrc]="element[column.dataSourceProperty!]"
                  width="35"
                  height="35"
                  class="rounded-circle"
                />
              </ng-container>
            }
            @if (column.type === 'text') {
              <ng-container #textColumn>
                {{ element[column.dataSourceProperty!] || (column.defaultValue ? column.defaultValue : null) }}
              </ng-container>
            }
            @if (column.type === 'date') {
              <ng-container #textColumn>
                {{ element[column.dataSourceProperty!] | date: 'dd/MM/yyyy' }}
              </ng-container>
            }
            @if (column.type === 'switch') {
              @for (switchOption of column.switchOptions; track switchOption) {
                @if (element[column.dataSourceProperty!] === switchOption.value) {
                  <span [ngClass]="[switchOption.classes, 'rounded', 'f-w-600', 'p-6', 'p-y-4', 'f-s-12']">
                    {{ switchOption.label }}
                  </span>
                }
              }
            }
            @if (column.type === 'buttons') {
              @for (button of column.buttonOptions.buttons; track button) {
                @if (button.actionType === 'link') {
                  <a
                    mat-button
                    [ngClass]="[
                      button.backgroundColorClass ?? 'bg-light-primary',
                      button.textColorClass ?? 'text-primary',
                      'f-w-600',
                      'm-l-4'
                    ]"
                    [routerLink]="button.getRouterLink(element)"
                    [disabled]="button.disable ? button.disable(element) : false"
                    [hidden]="button.hide ? button.hide(element) : false"
                  >
                    {{ button.label }}
                  </a>
                } @else if (button.actionType === 'click') {
                  <button
                    mat-button
                    [ngClass]="[
                      button.backgroundColorClass ?? 'bg-light-primary',
                      button.textColorClass ?? 'text-primary',
                      'f-w-600',
                      'm-l-4'
                    ]"
                    (click)="this.buttonClick.emit({ action: button.id, row: element })"
                    [disabled]="button.disable ? button.disable(element) : false"
                    [hidden]="button.hide ? button.hide(element) : false"
                  >
                    {{ button.label }}
                  </button>
                }
              }
            }
          </td>
        </ng-container>
      }
      <tr mat-header-row *matHeaderRowDef="tableConfiguration.displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableConfiguration.displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="tableConfiguration.paginator.pageSizeOptions" aria-label="Select page"></mat-paginator>
  }
</ng-container>
