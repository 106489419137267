import { NgModule } from '@angular/core';
import { CommonModule, IMAGE_CONFIG, NgOptimizedImage } from '@angular/common';
import { PtcChartComponent } from './charts/ptc-chart/ptc-chart.component';
import { PtcButtonToggleGroupComponent } from './ptc-button-toggle-group/ptc-button-toggle-group.component';
import { PtcTableComponent } from './ptc-table/ptc-table.component';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MaterialModule } from '../material.module';
import { PtcPageHeaderComponent } from './ptc-page-header/ptc-page-header.component';
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';
import { PtcBackButtonComponent } from './ptc-back-button/components/ptc-back-button/ptc-back-button.component';
import { PtcBackButtonContainerComponent } from './ptc-back-button/containers/ptc-back-button-container/ptc-back-button-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from 'safe-pipe';
import { DefaultImgPipe } from './pipes/default-img.pipe';
import { CustomerNutritionChartComponent } from './charts/customer-nutrition-chart/customer-nutrition-chart.component';
import { CustomerStepsChartComponent } from './charts/customer-steps-chart/customer-steps-chart.component';
import { CustomerWeightsChartComponent } from './charts/customer-weights-chart/customer-weights-chart.component';

@NgModule({
  declarations: [
    PtcTableComponent,
    PtcButtonToggleGroupComponent,
    PtcChartComponent,
    PtcPageHeaderComponent,
    PtcBackButtonComponent,
    PtcBackButtonContainerComponent,
    DefaultImgPipe,
    CustomerStepsChartComponent,
    CustomerWeightsChartComponent,
    CustomerNutritionChartComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    NgApexchartsModule,
    TablerIconsModule.pick(TablerIcons),
    FormsModule,
    ReactiveFormsModule,
    SafePipe,
    NgOptimizedImage
  ],
  exports: [
    CommonModule,
    PtcTableComponent,
    PtcButtonToggleGroupComponent,
    PtcChartComponent,
    PtcPageHeaderComponent,
    PtcBackButtonContainerComponent,
    SafePipe,
    DefaultImgPipe,
    CustomerStepsChartComponent,
    CustomerWeightsChartComponent,
    CustomerNutritionChartComponent,
    NgOptimizedImage
  ],
  providers: [
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 35
      }
    }
  ]
})
export class SharedModule {}
