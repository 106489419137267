import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ptc-page-header',
  templateUrl: './ptc-page-header.component.html',
  styleUrl: './ptc-page-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PtcPageHeaderComponent {
  @Input() title: string;
  @Input() buttonConfig?: {
    label?: string;
    icon?: string;
  };
  @Input() backButton?: {
    defaultLink?: string;
    link?: string;
    disabled?: boolean;
  };

  @Output() buttonClicked = new EventEmitter<void>();

  onClick() {
    this.buttonClicked.emit();
  }
}
