@if (!item.roles || item.roles.includes(role)) {
  @if (item.navCap) {
    <div mat-subheader class="nav-caption">{{ item.navCap }}</div>
  }
  @if (!item.navCap) {
    <a
      mat-list-item
      (click)="onItemSelected(item)"
      [ngClass]="{
        'mat-toolbar mat-primary activeMenu': item.route ? router.isActive(item.route, true) : false
      }"
      class="menu-list-item"
    >
      <i-tabler class="routeIcon" name="{{ item.iconName }}" matListItemIcon></i-tabler>
      <span class="hide-menu">{{ item.displayName }}</span>
    </a>
  }
}
