import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, of, tap, first } from 'rxjs';
import { RequestDataService } from './request-data.service';
import { RequestActions } from './request.actions';
import { NotificationService } from 'src/app/core/notification/notification.service';

@Injectable()
export class RequestEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private requestDataService: RequestDataService
  ) {}

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestActions.getAll),
      exhaustMap(() =>
        this.requestDataService.getAll().pipe(
          first(),
          map((requests) => RequestActions.getAllSuccess({ requests: requests })),
          catchError((error) => of(RequestActions.getAllError({ error: error })))
        )
      )
    )
  );

  getAllError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RequestActions.getAllError),
        tap(() => this.notificationService.error('Ops. Qualcosa è andato storto durante il caricamento dei dati'))
      ),
    { dispatch: false }
  );

  accept$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestActions.accept),
      exhaustMap((action) =>
        this.requestDataService.accept(action.id).pipe(
          first(),
          map(() => RequestActions.acceptSuccess({ id: action.id })),
          catchError((error) => of(RequestActions.acceptError({ error: error })))
        )
      )
    )
  );

  acceptError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RequestActions.acceptError),
        // eslint-disable-next-line quotes
        tap(() => this.notificationService.error("Ops. Qualcosa è andato storto durante l'accettazione della richiesta."))
      ),
    { dispatch: false }
  );

  deny$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestActions.deny),
      exhaustMap((action) =>
        this.requestDataService.deny(action.id).pipe(
          first(),
          map(() => RequestActions.denySuccess({ id: action.id })),
          catchError((error) => of(RequestActions.denyError({ error: error })))
        )
      )
    )
  );

  denyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RequestActions.denyError),
        tap(() => this.notificationService.error('Ops. Qualcosa è andato storto durante il rifiuto della richiesta.'))
      ),
    { dispatch: false }
  );

  loadRequestsCounter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestActions.loadRequestsCounter, RequestActions.acceptSuccess, RequestActions.denySuccess),
      exhaustMap(() =>
        this.requestDataService.getRequestsCounter().pipe(
          first(),
          map((counter) => RequestActions.loadRequestsCounterSuccess({ counter: counter })),
          catchError((error) => of(RequestActions.loadRequestsCounterError({ error: error })))
        )
      )
    )
  );
}
