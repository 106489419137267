import { Component, Output, EventEmitter, Input, ViewEncapsulation, ChangeDetectionStrategy, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserRole } from 'src/app/core/auth/data/auth.model';
import { AuthenticationService } from 'src/app/core/auth/data/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @ViewChild('dataTemplate') dataTemplate: TemplateRef<Element>;

  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();
  user$ = this.authenticationService.user$;
  photoURL$ = this.authenticationService.photoURL$;
  role$ = this.authenticationService.role$;
  hasMultipleRoles$ = this.authenticationService.hasMultipleRoles$;

  showFiller = false;

  UserRole = UserRole;

  constructor(
    public dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {}

  logout() {
    this.authenticationService.signOut();
  }

  showDataForm() {
    this.dialog.open(this.dataTemplate, {
      width: '1000px',
      height: '600px'
    });
  }

  selectRole(role: UserRole) {
    this.authenticationService.selectRole(role);
  }
}
