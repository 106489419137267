import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { PtcTableConfiguration } from './ptc-table.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PtcButtonToggleGroupOption } from '../ptc-button-toggle-group/ptc-button-toggle-group.model';

@Component({
  selector: 'app-ptc-table',
  templateUrl: './ptc-table.component.html',
  styleUrl: './ptc-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PtcTableComponent<T, K> implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() dataSource: T[] = [];

  @Input() tableConfiguration: PtcTableConfiguration<T, K> = {
    displayedColumns: [],
    columns: [],
    paginator: {
      pageSizeOptions: []
    },
    filter: {
      search: undefined,
      buttonToggle: undefined
    }
  };

  @Output() buttonClick = new EventEmitter<{ action: string; row: T }>();

  matDataSource: MatTableDataSource<T>;
  selectedFilterOption: PtcButtonToggleGroupOption<K>;

  ngOnInit() {
    this.refreshTable();
    this.applyFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']) {
      this.refreshTable();
    }
  }

  ngAfterViewInit() {
    this.matDataSource.paginator = this.paginator;
  }

  initFilters() {
    const buttonToggleFilter = this.tableConfiguration.filter?.buttonToggle;

    if (buttonToggleFilter) {
      this.selectedFilterOption = this.selectedFilterOption ?? buttonToggleFilter.defaultOption;
    }

    this.matDataSource.filterPredicate = (data, filter) => {
      // Transform the data into a lowercase string of all property values.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataStr = Object.keys(data as unknown as Record<string, any>)
        .reduce((currentTerm: string, key: string) => {
          // Use an obscure Unicode character to delimit the words in the concatenated string.
          // This avoids matches where the values of two columns combined will match the user's query
          // (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
          // that has a very low chance of being typed in by somebody in a text field. This one in
          // particular is "White up-pointing triangle with dot" from
          // https://en.wikipedia.org/wiki/List_of_Unicode_characters
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return currentTerm + (data as unknown as Record<string, any>)[key] + '◬';
        }, '')
        .toLowerCase();

      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();

      const buttonToggleFilterResult = buttonToggleFilter?.filter ? buttonToggleFilter?.filter(data, this.selectedFilterOption) : true;

      return dataStr.indexOf(transformedFilter) != -1 && buttonToggleFilterResult;
    };
  }

  applyFilter(event?: Event) {
    if (event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.matDataSource.filter = filterValue;
    } else {
      this.matDataSource.filter += ' ';
    }

    if (this.matDataSource.paginator) {
      this.matDataSource.paginator.firstPage();
    }
  }

  private refreshTable() {
    if (this.dataSource) {
      this.matDataSource = new MatTableDataSource(this.dataSource);
      this.initFilters();
    }
  }
}
