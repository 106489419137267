import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from 'src/app/core/navigation/nav.service';

@Component({
  selector: 'app-ptc-back-button-container',
  templateUrl: './ptc-back-button-container.component.html',
  styleUrl: './ptc-back-button-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PtcBackButtonContainerComponent {
  @Input() link: string;
  @Input() defaultLink: string;

  @Input() disabled: boolean;

  constructor(
    private router: Router,
    private navService: NavService
  ) {}

  back() {
    if (!this.disabled) {
      if (this.link) {
        void this.router.navigate([this.link]);
      } else if (this.defaultLink) {
        void this.router.navigate([this.defaultLink]);
      } else {
        this.navService.back();
      }
    }
  }
}
