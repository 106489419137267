export const FEATURE_NAME = 'notifications';

export interface NotificationState {
  loading?: boolean;
  loaded?: boolean;
  notifications?: Notification[];
  error?: Error;
}

export interface Notification {
  type: NotificationType;
}

export enum NotificationType {
  MissingData = 'MissingData'
}
