@for (option of options; track option) {}

<ng-container>
  @for (option of options; track $index) {
    @if (option.value === selectedOption.value) {
      <button class="m-r-12" mat-flat-button color="primary" (click)="onSelectOption(option)">
        {{ option.label }}
      </button>
    } @else {
      <button class="m-r-12" mat-raised-button color="secondary" (click)="onSelectOption(option)">
        {{ option.label }}
      </button>
    }
  }
</ng-container>
