<div class="m-t-12 row">
  <div class="col-3">
    <div class="step-resume-container p-24">
      @for (stepItem of steps; track $index) {
        <div class="step-resume">
          @if (getFormGroup(stepItem.formGroupName).valid && step >= $index) {
            <i-tabler name="circle-check" style="color: #5d87ff"></i-tabler>
          } @else {
            <i-tabler name="circle-dashed"></i-tabler>
          }
          <h4 class="m-l-12" [ngClass]="{ 'text-primary': step === $index }">{{ stepItem.label }}</h4>
        </div>
      }
    </div>
    <div class="m-t-48 d-flex justify-content-between">
      <button mat-stroked-button (click)="step = step - 1" [disabled]="step === 0">Indietro</button>
      @if (step !== steps.length - 1) {
        <button mat-flat-button color="primary" (click)="step = step + 1" [disabled]="!canGoNext()">Avanti</button>
      } @else {
        <button mat-flat-button color="primary" (click)="save()" [disabled]="dataForm.invalid">Salva</button>
      }
    </div>
  </div>
  <div class="col-2"></div>
  <div class="col-7">
    <div class="row w-60">
      <div class="col">
        @if (step === 0) {
          <h2>Scegli il giorno</h2>
          <p>Indica il giorno per il quale vuoi inserire i dati</p>
          <form [formGroup]="getFormGroup('dateForm')" class="m-t-24">
            <mat-card style="width: 250px">
              <mat-calendar
                [dateClass]="dateClass"
                [selected]="getFormGroup('dateForm').get('selectedDate').value"
                (selectedChange)="updateFormDate($event)"
                [dateFilter]="dateCanBeSelected"
              ></mat-calendar>
            </mat-card>
          </form>
        }
        @if (step === 1) {
          <h2>Quanti passi hai fatto?</h2>
          <p>Indica il numero di passi fatti nel giorno indicato</p>
          <form [formGroup]="getFormGroup('stepsForm')" class="m-t-24">
            <mat-form-field appearance="outline">
              <mat-label>Passi</mat-label>
              <input formControlName="steps" min="0" matInput type="number" />
            </mat-form-field>
          </form>
        }
        @if (step === 2) {
          <h2>Ti sei allenato/a?</h2>
          <p>Indica se ti sei allenato/a nel giorno indicato</p>
          <form [formGroup]="getFormGroup('trainingForm')" class="m-t-24">
            <mat-checkbox class="m-b-24" formControlName="trained">Ti sei allenato/a?</mat-checkbox>
          </form>
        }
        @if (step === 3) {
          <h2>Quale è il tuo peso?</h2>
          <p>Indica il peso che avevi nel giorno indicato</p>
          <form [formGroup]="getFormGroup('weightForm')" class="m-t-24">
            <mat-form-field appearance="outline">
              <mat-label>Peso</mat-label>
              <input formControlName="weight" min="0" matInput type="number" />
            </mat-form-field>
          </form>
        }
        @if (step === 4) {
          <h2>Quanto hai mangiato?</h2>
          <p>Inserisci i macronutrienti e le calorie che hai mangiato nel giorno indicato</p>
          <form [formGroup]="getFormGroup('nutritionForm')" class="m-t-24">
            <mat-checkbox class="m-b-24" formControlName="cheatDay" #cheatDay>Hai sgarrato?</mat-checkbox>
            <mat-form-field appearance="outline">
              <mat-label>Carboidrati</mat-label>
              <input formControlName="carbohydrates" min="0" matInput type="number" />
            </mat-form-field>
            <br />
            <mat-form-field appearance="outline">
              <mat-label>Proteine</mat-label>
              <input formControlName="proteins" min="0" matInput type="number" />
            </mat-form-field>
            <br />
            <mat-form-field appearance="outline">
              <mat-label>Grassi</mat-label>
              <input formControlName="fats" min="0" matInput type="number" />
            </mat-form-field>
            <br />
            <mat-form-field appearance="outline">
              <mat-label>Calorie</mat-label>
              <input formControlName="calories" min="0" matInput type="number" />
            </mat-form-field>
          </form>
        }
      </div>
    </div>
  </div>
</div>
