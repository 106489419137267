import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CustomRouterStateSerializer } from './router/custom-router-state-serializer';
import { EntityStoreModule } from './store-entity';
import { metaReducers, reducers } from './store/core.state';
import { ToastrModule } from 'ngx-toastr';
import { AuthEffects } from './auth/data/auth.effects';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthenticationService } from './auth/data/authentication.service';
import { authInterceptor } from './interceptors/auth.interceptor';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    //ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer
    }),
    EffectsModule.forRoot([AuthEffects]),
    StoreDevtoolsModule.instrument({
      name: 'ptconnect-web'
    }),
    EntityStoreModule,
    //ngrx-toast
    ToastrModule.forRoot()
  ],
  providers: [provideHttpClient(withInterceptors([authInterceptor]))]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private afAuth: AngularFireAuth,
    private authService: AuthenticationService
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
    this.afAuth
      .onAuthStateChanged((user) => {
        this.authService.stateChanged(user);
      })
      .catch(() => console.error('Error on auth state changed'));
  }
}
