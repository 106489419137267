import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DailyUserData, UserData } from './user-data.model';

@Injectable({
  providedIn: 'root'
})
export class UserDataDataService {
  constructor(private http: HttpClient) {}

  save(date: Date, data: DailyUserData) {
    return this.http.post<void>(`/api/data/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`, data);
  }

  loadDatesWithData(year: number, month: number) {
    return this.http.get<string[]>(`/api/data/count/?year=${year}&month=${month}`);
  }

  loadDataByDate(date: Date) {
    return this.http.get<DailyUserData>(`/api/data/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`);
  }

  loadUserData() {
    return this.http.get<UserData>('/api/data');
  }
}
