<button mat-icon-button [matMenuTriggerFor]="notificationMenu" aria-label="Notifiche" title="Notifiche">
  <mat-icon
    aria-hidden="false"
    [matBadge]="notificationsCounter$ | async"
    [matBadgeHidden]="!!!(notificationsCounter$ | async)"
    matBadgeSize="small"
    class="d-flex icon-20"
  >
    <i-tabler name="bell" class="icon-18 d-flex"></i-tabler>
  </mat-icon>
</button>
<mat-menu #notificationMenu="matMenu" class="topbar-dd cardWithShadow">
  <div class="d-flex align-items-center p-x-32 p-y-16 ng-tns-c1923052698-7">
    <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Notifiche</h6>
  </div>
  @if ((notificationsCounter$ | async) > 0) {
    @if (notifications$ | async; as notifications) {
      @for (notification of notifications; track $index) {
        <button
          mat-menu-item=""
          class="mat-mdc-menu-item mat-mdc-focus-indicator p-x-32 p-y-16"
          role="menuitem"
          tabindex="0"
          aria-disabled="false"
        >
          @switch (notification.type) {
            @case (NotificationType.MissingData) {
              <app-missing-data-notification-item></app-missing-data-notification-item>
            }
          }
        </button>
      }
    }
  } @else {
    <button
      [disabled]="true"
      class="mat-mdc-menu-item mat-mdc-focus-indicator p-x-32 p-y-16"
      role="menuitem"
      tabindex="0"
      aria-disabled="false"
    >
      <span class="mat-mdc-menu-item-text">
        <div class="d-flex align-items-center">
          <div class="m-l-16">
            <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">Nessuna notifica da mostrare</h5>
          </div>
        </div>
      </span>
    </button>
  }
</mat-menu>
