<app-ptc-page-header [title]="'Richieste'"></app-ptc-page-header>
<div class="row m-t-24">
  <div class="col-lg-12">
    <mat-card class="cardWithShadow">
      <mat-card-content class="p-24">
        @if (requests$ | async; as map) {
          <div class="table-responsive m-t-16">
            <app-ptc-table (buttonClick)="buttonClick($event)" [dataSource]="map" [tableConfiguration]="tableConfiguration"></app-ptc-table>
          </div>
        }
      </mat-card-content>
    </mat-card>
  </div>
</div>
