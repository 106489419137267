import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Notification } from './notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationDataService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<Notification[]>('/api/notification');
  }
}
