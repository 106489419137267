import { Component, Input } from '@angular/core';
import { NavItem } from './nav-item';
import { Router } from '@angular/router';
import { NavService } from 'src/app/core/navigation/nav.service';
import { UserRole } from 'src/app/core/auth/data/auth.model';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: []
})
export class AppNavItemComponent {
  @Input() item: NavItem;
  @Input() depth: number;
  @Input() role: UserRole;

  constructor(
    public navService: NavService,
    public router: Router
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  async onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      await this.router.navigate([item.route]);
    }

    // scroll
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0
    });
  }
}
