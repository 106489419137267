import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Notification } from './notification.model';

export const NotificationActions = createActionGroup({
  source: 'Notification',
  events: {
    getAll: emptyProps(),
    getAllSuccess: props<{ notifications: Notification[] }>(),
    getAllError: props<{ error: Error }>()
  }
});
