import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User, UserRole } from './auth.model';
import { AuthError } from 'firebase/auth';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    signInWithEmailAndPassword: props<{ email: string; password: string }>(),
    signInWithGoogle: emptyProps(),
    signInSuccess: emptyProps(),
    signInError: props<{ error: AuthError }>(),
    createUserWithEmailAndPassword: props<{ email: string; password: string }>(),
    createUserWithEmailAndPasswordSuccess: emptyProps(),
    createUserWithEmailAndPasswordError: props<{ error: AuthError }>(),
    signOut: props<{ redirect: boolean }>(),
    signOutSuccess: props<{ redirect: boolean }>(),
    signOutError: props<{ error: AuthError }>(),
    authStateChanged: props<{ user: User }>(),
    completeProfile: props<{ displayName: string; roles: UserRole[] }>(),
    completeProfileSuccess: props<{ displayName: string; roles: UserRole[] }>(),
    completeProfileError: props<{ error: AuthError }>(),
    loadRoles: props<{ uid: string }>(),
    loadRolesSuccess: props<{ roles: UserRole[] }>(),
    loadRolesError: props<{ error: AuthError }>(),
    selectRole: props<{ role: UserRole }>(),
    selectRoleSuccess: emptyProps(),
    updateProfile: props<{ displayName: string; photo?: File }>(),
    updateProfileSuccess: props<{ displayName: string; photo: string }>(),
    updateProfileError: props<{ error: AuthError }>(),
    updatePassword: props<{ oldPassword: string; newpassword: string }>(),
    updatePasswordSuccess: props<{ newpassword: string }>(),
    updatePasswordError: props<{ error: AuthError }>()
  }
});
