import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Request } from '../../store/request.model';
import { PtcTableConfiguration } from 'src/app/shared/ptc-table/ptc-table.model';
import { RequestsTableConfiguration } from './requests-table.config';
import { RequestService } from '../../store/request.service';

@Component({
  selector: 'app-requests-container',
  templateUrl: './requests-container.component.html',
  styleUrl: './requests-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestsContainerComponent {
  tableConfiguration: PtcTableConfiguration<Request, string> = RequestsTableConfiguration;

  requests$ = this.requestService.requests$;

  constructor(private requestService: RequestService) {}

  buttonClick(value: { action: string; row: Request }) {
    switch (value.action) {
      case 'accept':
        this.requestService.accept(value.row.id);
        break;

      case 'deny':
        this.requestService.deny(value.row.id);
        break;
    }
  }
}
