import { NgModule } from '@angular/core';
import { NotificationMenuItemComponent } from './containers/notification-menu-item/notification-menu-item.component';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';
import { notificationReducer } from './store/notification.reducer';
import { NotificationEffects } from './store/notification.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FEATURE_NAME } from './store/notification.model';
import { MissingDataNotificationItemComponent } from './components/missing-data-notification-item/missing-data-notification-item.component';

@NgModule({
  declarations: [NotificationMenuItemComponent, MissingDataNotificationItemComponent],
  imports: [
    SharedModule,
    MaterialModule,
    TablerIconsModule.pick(TablerIcons),
    StoreModule.forFeature(FEATURE_NAME, notificationReducer),
    EffectsModule.forFeature([NotificationEffects])
  ],
  exports: [NotificationMenuItemComponent]
})
export class NotificationModule {}
