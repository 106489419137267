import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { mergeMap, of, skipWhile } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentRole, selectRolesLoaded, selectUser } from '../auth/data/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class CompleteUserGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate() {
    return this.store.select(selectUser).pipe(
      mergeMap((user) => {
        if (user) {
          if (!user.displayName) {
            return this.router.navigate(['/complete-profile']);
          }

          return this.store.select(selectRolesLoaded).pipe(
            skipWhile((loaded) => !loaded),
            mergeMap(() => this.store.select(selectCurrentRole)),
            mergeMap((role) => {
              if (!role) {
                return this.router.navigate(['/complete-profile']);
              } else {
                return of(true);
              }
            })
          );
        }

        return this.router.navigate(['/authentication/login']);
      })
    );
  }
}
