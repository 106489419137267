import { Action, createReducer, on } from '@ngrx/store';
import { NotificationState } from './notification.model';
import { NotificationActions } from './notification.actions';

export const initialState: NotificationState = {
  loading: false,
  loaded: undefined,
  notifications: undefined,
  error: undefined
};

export const notificationReducer = (state: NotificationState | undefined, action: Action) => {
  return reducer(state, action);
};

const reducer = createReducer(
  initialState,
  on(NotificationActions.getAll, (state) => ({
    ...state,
    loading: true,
    loaded: undefined,
    customers: undefined
  })),
  on(NotificationActions.getAllSuccess, (state, { notifications }) => ({
    ...state,
    loading: false,
    loaded: true,
    notifications: notifications
  })),
  on(NotificationActions.getAllError, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: error
  }))
);
