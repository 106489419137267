import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, GuardResult, MaybeAsync, Router } from '@angular/router';
import { mergeMap, of, skipWhile, withLatestFrom } from 'rxjs';
import { selectRouter } from '../router';
import { Store } from '@ngrx/store';
import { UserRole } from '../auth/data/auth.model';
import { selectCurrentRole, selectRolesLoaded } from '../auth/data/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate() {
    return this.store.select(selectRolesLoaded).pipe(
      skipWhile((loaded) => !loaded),
      mergeMap(() => this.store.select(selectCurrentRole)),
      withLatestFrom(this.store.select(selectRouter)),
      mergeMap(([role, router]) => {
        if (!router.data.role || router.data.role === role) {
          return of(true);
        } else {
          if (role === UserRole.Customer) {
            return this.router.navigate(['/customer-dashboard']);
          } else if (role === UserRole.PT) {
            return this.router.navigate(['/dashboard']);
          }
        }

        return of(false);
      })
    );
  }

  canActivateChild(): MaybeAsync<GuardResult> {
    return this.canActivate();
  }
}
